<template>
	<header v-if="user" class="navbar sticky-top flex-md-nowrap p-0 shadow z-1" data-bs-theme="dark">
		<router-link class="navbar-brand fs-5 p-2 text-wrap" to="/">
			<img src="./assets/logo.png" class="d-inline-block align-text-bottom bg-light rounded-circle" alt="Logo" height="24" />
			Sistem Informasi Data Siswa
		</router-link>
		<ul class="navbar-nav flex-row d-md-none ms-auto">
			<li class="nav-item text-nowrap">
				<button
					class="navbar-toggler py-1 px-2 m-1"
					type="button"
					data-bs-toggle="offcanvas"
					data-bs-target="#sidebarMenu"
					aria-controls="sidebarMenu"
					aria-expanded="false"
					aria-label="Toggle navigation">
					<span class="navbar-toggler-icon small"></span>
				</button>
			</li>
		</ul>
	</header>
	<div v-if="user" class="container-fluid small">
		<div class="row">
			<div class="sidebar col-md-4 col-lg-3 col-xxl-2 p-0 border-end border-secondary-subtle shadow">
				<side-bar :user="user" />
			</div>
			<main class="col-md-8 col-lg-9 col-xxl-10 ms-sm-auto px-3 py-2">
				<router-view />
			</main>
		</div>
	</div>
</template>
<script>
import WebSocketService from "@/components/WebSocketService";
import SideBar from "./components/SideBar.vue";
export default {
	name: "App",
	data() {
		return {};
	},
	components: { SideBar },
	computed: {
		user() {
			return this.$store.getters.getUserData;
		},
	},
	created() {
		WebSocketService.connect("/backend-admin");
	},
	mounted() {},
	methods: {},
};
</script>
<style>
header {
	background-color: #184b89;
}

.offcanvas-header,
.offcanvas-body,
.sidebar {
	background-color: #eff7ff;
}

@media (min-width: 768px) {
	.sidebar {
		position: fixed;
		display: block !important;
		overflow-y: auto;
		height: calc(100vh - 43px);
	}
}

#cari {
	background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="16" fill="gray" class="bi bi-search" viewBox="0 0 16 16"><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/%3E%3C/svg%3E');
	background-repeat: no-repeat;
	background-position: 7px;
	background-position-y: center;
	text-indent: 21px;
	background-size: 16px;
}

::-webkit-input-placeholder {
	font-style: italic;
}

:-moz-placeholder {
	font-style: italic;
}

::-moz-placeholder {
	font-style: italic;
}

:-ms-input-placeholder {
	font-style: italic;
}

.v-select {
	--vs-line-height: 1.36;
	--vs-border-radius: 0.25rem;
	--vs-controls-size: 0.75;
	--vs-border-width: 1px;
	--vs-border-style: solid;
	--vs-border-color: #dee2e6;
	--vs-search-input-placeholder-color: #595c5f;
	--vs-font-size: 0.875rem;
}

.reset-button {
	background: transparent;
	border: 1px solid #dee2e6;
	fill: rgba(60, 60, 60, 0.5);
	cursor: pointer;
}

.select-wrapper {
	position: relative;
	display: inline-block;
}

.slide-up-enter-active,
.slide-up-leave-active {
	transition: all 0.3s ease-out;
}

.slide-up-enter-from {
	opacity: 0;
	transform: translateY(20px);
}

.slide-up-leave-to {
	opacity: 0;
	transform: translateY(-20px);
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

.table-responsive table {
	border-collapse: separate;
	border-spacing: 0;
}

table .position-sticky {
	animation: shadow-outside linear;
	animation-timeline: scroll(nearest inline);
}

.table-responsive tbody tr:nth-child(odd) td {
	background-color: #f2f2f2;
}

.table-responsive tbody tr:nth-child(even) td {
	background-color: #ffffff;
}

.table-responsive tbody tr:hover td {
	background-color: #ececec;
}

@keyframes shadow-inside {
	from {
		box-shadow: inset -10px -10px 15px 0px rgb(0 0 0 / 0.3);
	}

	to {
		box-shadow: none;
	}
}

@keyframes shadow-outside {
	from {
		box-shadow: none;
	}

	to {
		box-shadow: 10px 10px 15px 0px rgb(0 0 0 / 0.3);
	}
}
</style>
