<template>
	<div
		class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-2 pb-1 mb-2 border-bottom">
		<h1 class="h2 text-primary-emphasis">Dasbor</h1>
	</div>
	<div class="my-3">
		<small class="text-muted">Selamat Datang,</small>
		<h5>{{ user.nama }}</h5>
		<div class="card shadow my-3">
			<p class="card-header">
				Progres kelengkapan data :
				<strong :style="'color:' + warna">{{ progress.persen_total }}%</strong>
			</p>
			<div class="card-body row row-cols-xl-4 row-cols-lg-3 row-cols-sm-2 g-3 text-center justify-content-around">
				<div>
					<CircularProgress class="col-4 fs-4 mb-2" :progress="progress.persen_detail_siswa" />
					<h6>Data Rinci Siswa</h6>
					<router-link to="/siswa/detail" class="btn btn-sm btn-outline-secondary">Lihat detail
						&raquo;</router-link>
				</div>
				<div>
					<CircularProgress class="col-4 fs-4 mb-2" :progress="progress.persen_data_ayah"
						:status_ortu_id="ayah_tidak_ada" />
					<h6>Data Ayah</h6>
					<router-link to="/siswa/data-ayah" class="btn btn-sm btn-outline-secondary">Lihat detail
						&raquo;</router-link>
				</div>
				<div>
					<CircularProgress class="col-4 fs-4 mb-2" :progress="progress.persen_data_ibu"
						:status_ortu_id="ibu_tidak_ada" />
					<h6>Data Ibu</h6>
					<router-link to="/siswa/data-ibu" class="btn btn-sm btn-outline-secondary">Lihat detail
						&raquo;</router-link>
				</div>
				<div>
					<CircularProgress class="col-4 fs-4 mb-2" :progress="progress.persen_data_wali" />
					<h6>Data Wali</h6>
					<router-link to="/siswa/data-wali" class="btn btn-sm btn-outline-secondary">Lihat detail
						&raquo;</router-link>
				</div>
			</div>
		</div>
		<div class="card shadow my-3">
			<div class="card-body">
				<p class="card-text small">
					Silakan lengkapi data pada masing-masing bagian hingga mencapai progres 100%. Jika mengalami
					permasalahan dalam proses pengisian data, silakan hubungi wali kelas.
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import CircularProgress from "@/components/CircularProgress.vue";

export default {
	name: "DasborSiswa",
	data() {
		return {
			progress: {
				persen_detail_siswa: 0,
				persen_data_ayah: 0,
				persen_data_ibu: 0,
				persen_data_wali: 0,
				persen_total: 0,
			},
			ayah_tidak_ada: 0,
			ibu_tidak_ada: 0
		};
	},
	components: {
		CircularProgress,
	},
	computed: {
		user() {
			return this.$store.getters.getUserData;
		},
		token() {
			return this.$store.getters.getToken;
		},
		warna() {
			if (this.progress.persen_total == 0) {
				return "#000";
			} else if (this.progress.persen_total < 33) {
				return "red";
			} else if (this.progress.persen_total < 66) {
				return "orange";
			} else if (this.progress.persen_total < 100) {
				return "#5394fd";
			} else {
				return "green";
			}
		},
	},
	watch: {},
	mounted() {
		this.getPersentaseProgres();
	},
	beforeUnmount() { },
	methods: {
		async getPersentaseProgres() {
			try {
				const response = await this.axios.post(
					"/data-siswa/persentase-siswa",
					{},
					{
						headers: { Authorization: this.token },
					}
				);
				if (response.data) {
					const hasil = response.data.progressDataSiswa;
					this.progress = {
						persen_detail_siswa: Math.round((hasil.persen_detail_siswa + Number.EPSILON) * 100) / 100,
						persen_data_ayah: Math.round((hasil.persen_data_ayah + Number.EPSILON) * 100) / 100,
						persen_data_ibu: Math.round((hasil.persen_data_ibu + Number.EPSILON) * 100) / 100,
						persen_data_wali: Math.round((hasil.persen_data_wali + Number.EPSILON) * 100) / 100,
						persen_total: Math.round((hasil.persen_total + Number.EPSILON) * 100) / 100,
					};
					this.ayah_tidak_ada = hasil.ayah_tidak_ada;
					this.ibu_tidak_ada = hasil.ibu_tidak_ada;
				}
			} catch (err) {
				if (err.response) {
					if (err.response.status == 401) {
						this.$swal({
							title: "Gagal",
							text: "Sesi berakhir. Silakan login ulang.",
							icon: "error",
							confirmButtonText: "Baik",
						}).then(() => {
							this.$store.dispatch("logoutUser");
							window.location.href = this.$sso + "/" + this.$appId;
						});
					} else {
						console.log(err);
					}
				} else {
					console.log(err);
				}
			}
		},
	},
};
</script>

<style></style>
