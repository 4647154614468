import { createRouter, createWebHistory } from "vue-router";
import SetToken from "../components/SetToken";
import HomeView from "../views/HomeView.vue";
import SemuaSiswaView from "@/views/SemuaSiswaView.vue";
import AnggotaRombelView from "@/views/AnggotaRombelView";
import DataSiswaView from "@/views/DataSiswaView";
import PengaturanView from "../views/PengaturanView";
import ForbiddenView from "../views/ForbiddenView";
import NotFound from "../components/NotFound";

const routes = [
	{
		path: "/auth/:token?",
		name: "setToken",
		component: SetToken,
	},
	{
		path: "/",
		name: "home",
		component: HomeView,
		meta: { umum: true },
	},
	{
		path: "/data-siswa",
		meta: { bp: true },
		children: [
			{
				path: "",
				name: "semuaSiswa",
				component: SemuaSiswaView,
			},
			{
				path: ":siswa_id",
				component: DataSiswaView,
			},
			{
				path: ":siswa_id/:data",
				component: DataSiswaView,
			},
		],
	},
	{
		path: "/rombel/:rombel_id",
		meta: { bp: true },
		children: [
			{
				path: "",
				name: "rombel",
				component: AnggotaRombelView,
			},
			{
				path: ":siswa_id",
				children: [
					{
						path: "",
						name: "anggotaRombel",
						component: DataSiswaView,
					},
					{
						path: ":data",
						name: "dataAnggotaRombel",
						component: DataSiswaView,
					},
				],
			},
		],
	},
	{
		path: "/data-siswa-walas/:siswa_id/:data",
		name: "dataSiswaWalas",
		component: DataSiswaView,
		meta: { walas: true },
	},
	{
		path: "/siswa/:data?",
		name: "siswa",
		component: DataSiswaView,
		meta: { siswa: true },
	},
	{
		path: "/pengaturan",
		name: "pengaturan",
		component: PengaturanView,
		meta: { umum: true },
	},
	{
		path: "/forbidden/:halaman(.*)?",
		name: "forbidden",
		meta: { umum: true },
		component: ForbiddenView,
	},
	{
		path: "/:catchAll(.*)",
		name: "NotFound",
		meta: { umum: true },
		component: NotFound,
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});
router.beforeEach((to, from, next) => {
	if (to !== from && from.meta.sedangEdit) {
		const answer = window.confirm("Anda sedang melakukan pengeditan. Apakah Anda yakin ingin meninggalkan halaman ini?");
		if (answer) {
			next();
		} else {
			next(false);
		}
	} else {
		next();
	}
});
export default router;
