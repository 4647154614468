<template>
  <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-2 pb-1 mb-2 border-bottom">
    <h1 class="h2 text-primary-emphasis">Dasbor</h1>
  </div>
  <div class="my-3">
    <div class="card shadow my-3 vl-parent" ref="progressCard">
      <p class="card-header">Progres kelengkapan data keseluruhan :
        <strong :style="'color:' + warna">{{ persen_total }}%</strong>
      </p>
      <div v-if="progressData.length"
        class="card-body row row-cols-xl-4 row-cols-lg-3 row-cols-sm-2 g-3 gy-4 text-center justify-content-around">
        <div v-for="d in progressData" :key="d">
          <CircularProgress class="col-4 fs-4 mb-2" :progress="d.persen_data_rombel" />
          <h6 class="mb-1">{{ d.nama_rombel }}</h6>
          <p class="text-center mb-1 small text-muted">~ {{ d.nama_walas }}</p>
          <div class="d-flex gap-2 justify-content-center">
            <router-link :to="'/rombel/' + d.rombel_id" class="btn btn-sm btn-outline-secondary">
              Lihat detail &raquo;
            </router-link>
            <button class="btn btn-sm btn-outline-primary" @click="downloadDataSiswa(d)"
              v-if="user.role === 'admin' || user.guru_id === d.wali_kelas">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" fill="currentColor" class="bi bi-download"
                viewBox="0 0 16 16">
                <path
                  d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                <path
                  d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div v-else-if="sedangMemuat" class="card-body d-flex align-items-center justify-content-center gap-2">
        <div class="spinner-border border-3 text-secondary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <div class="text-secondary d-flex align-items-baseline gap-1">
          <span>Memuat</span>
          <div class="spinner-grow text-secondary" style="width:0.15rem;height:0.15rem;" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div class="spinner-grow text-secondary" style="width:0.15rem;height:0.15rem;" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <div class="spinner-grow text-secondary" style="width:0.15rem;height:0.15rem;" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
      <div v-else class="card-body text-center">Data tidak ditemukan.</div>
    </div>
  </div>
</template>

<script>
import CircularProgress from '@/components/CircularProgress.vue';

export default {
  name: 'DasborKontrol',
  data() {
    return {
      persen_total: 0,
      progressData: [],
      sedangMemuat: false,
    }
  },
  components: {
    CircularProgress
  },
  computed: {
    user() {
      return this.$store.getters.getUserData;
    },
    token() {
      return this.$store.getters.getToken;
    },
    warna() {
      if (this.persen_total == 0) {
        return '#000';
      } else if (this.persen_total < 33) {
        return 'red';
      } else if (this.persen_total < 66) {
        return 'orange';
      } else if (this.persen_total < 100) {
        return '#5394fd';
      } else {
        return 'green'
      }
    }
  },
  watch: {
  },
  mounted() {
    this.getPersentaseProgres();
  },
  beforeUnmount() { },
  methods: {
    async getPersentaseProgres() {
      this.sedangMemuat = true;
      try {
        const response = await this.axios.post('/data-siswa/persentase-rombel-all', {
        }, {
          headers: { Authorization: this.token }
        });
        if (response.data) {
          setTimeout(() => {
            this.progressData = response.data.progressDataAll;
            let jumlah = 0;
            let persen = 0;
            for (const p of this.progressData) {
              persen += p.persen_data_rombel;
              jumlah++;
              p.persen_data_rombel = Math.round((p.persen_data_rombel + Number.EPSILON) * 100) / 100;
            }
            this.persen_total = Math.round((persen / jumlah + Number.EPSILON) * 100) / 100;
            let memuat = this.$loading.show({
              container: this.$refs.progressCard,
              loader: 'spinner'
            })
            setTimeout(() => {
              this.sedangMemuat = false;
              memuat.hide();
            }, 250)
          }, 500);
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silakan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$store.dispatch('logoutUser');
              window.location.href = this.$sso + "/" + this.$appId;
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async downloadDataSiswa(r) {
      let memuat = this.$loading.show({
        loader: 'bars'
      });
      await this.axios.post('/data-siswa/unduh', {
        rombel_id: r.rombel_id
      }, {
        headers: { Authorization: this.token },
        responseType: 'blob'
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
          const a = document.createElement('a');
          a.href = url;
          a.download = `DataSiswa - ${r.nama_rombel}.xlsx`;
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
        })
        .catch(err => {
          if (err.response) {
            if (err.response.status == 401) {
              this.$swal({
                title: 'Gagal',
                text: 'Sesi berakhir. Silahkan login ulang.',
                icon: 'error',
                confirmButtonText: 'Baik'
              }).then(() => {
                this.$store.dispatch('logoutUser');
                window.location.href = this.$sso + "/" + this.$appId;
              });
            } else {
              console.log(err);
            }
          } else {
            console.log(err)
          }
        });
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
  }
}
</script>

<style></style>